import React from 'react'

import styles from './frame.module.scss'
import { Decorator } from '../decorator/decorator'
import { Wrapper } from '../wrapper/wrapper'

export const Frame = ({ children, title, description, bgClassName, id }) => (
  <div id={id} className={`${styles.framed} ${bgClassName}`}>
    <Wrapper>
      <div className={styles.frame}>
        <div>
          <h2 className={styles.title}>{title}</h2>
          <Decorator />
        </div>
        <div>
          <div className={styles.description}>{description}</div>

          {children}
        </div>
      </div>
    </Wrapper>
  </div>
)
