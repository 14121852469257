import React from 'react'

import styles from './intro-limited.module.scss'
import logo from '../../images/favicon2.svg'
import { Decorator } from '../decorator/decorator'
import { MainMenu } from '../main-menu/main-menu'
import { Wrapper } from '../wrapper/wrapper'

export const IntroLimited = () => (
  <div className={styles.outerWrapper}>
    <Wrapper>
      <div className={styles.innerWrapper}>
        <div className={styles.contentTop}>
          <MainMenu type="primary" />
          <img src={logo} alt="" className={styles.logo} />
          <h1 className={styles.welcome}>Hi, we are Cloud&nbsp;Corridor.</h1>
          <Decorator />
        </div>
      </div>
    </Wrapper>
  </div>
)
