import React, { Component } from 'react'

import { Contact } from '../contact/contact'
import { MainMenu } from '../main-menu/main-menu'

import './footer.scss'

export class Footer extends Component {
  state = {
    menuIsHidden: false,
  }

  handleScroll = () => {
    this.setState({
      menuIsHidden: !(window.scrollY > 0),
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    return (
      <footer>
        <Contact />
        <MainMenu type="secondary" hidden={this.state.menuIsHidden} />

        <div className="wrapper">
          <a href="/privacy-policy">Privacy Policy</a>|
          <a href="/terms-of-service">Terms of Service</a>|
          <a href="/cookie-policy">Cookie Policy</a>
        </div>
      </footer>
    )
  }
}
