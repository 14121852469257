import React, { Component } from 'react'

import logo from '../../images/favicon2.svg'

import styles from './main-menu.module.scss'
import { Wrapper } from '../wrapper/wrapper'
import { IconContext } from 'react-icons'
import { IoIosMenu } from 'react-icons/io'

export class MainMenu extends Component {
  state = {
    expanded: false,
  }

  componentDidMount() {
    window.addEventListener('click', () => this.setState({ expanded: false }))
  }

  render() {
    const { type, hidden } = this.props
    const { expanded } = this.state
    return (
      <div
        onClick={event => {
          event.stopPropagation()
          this.setState({ expanded: !this.state.expanded })
        }}
        className={`${type === 'secondary' ? styles.fixed : ''} ${
          hidden ? styles.hidden : ''
        }`}
      >
        <Wrapper>
          <div
            className={`${styles.menu} ${styles[type]} ${
              expanded ? styles.expanded : ''
            }`}
          >
            <IconContext.Provider value={{ color: 'white' }}>
              <span className={styles.hamburger}>
                <IoIosMenu />
              </span>
            </IconContext.Provider>
            <a href="/" className={styles.logoLink}>
              <img src={logo} alt="" className={styles.logo} />
            </a>
            <span className={styles.links}>
              <a href="/" className={styles.menuItem}>
                Home
              </a>
              <a href="/about" className={styles.menuItem}>
                About Us
              </a>
              <a href="/services" className={styles.menuItem}>
                Services
              </a>
              <a href="/team" className={styles.menuItem}>
                Team
              </a>
            </span>
          </div>
        </Wrapper>
      </div>
    )
  }
}
