import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'
import image from '../../images/logo_cloudcorridor_open_graph.png'

const query = graphql`
  {
    graphCMSData {
      sectionHeadings {
        type
        text
      }
    }
  }
`

export const Layout = ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Helmet
          title="Cloud Corridor"
          meta={[
            {
              name: 'description',
              content: data.graphCMSData.sectionHeadings.find(
                heading => heading.type === 'AboutUs'
              ).text,
            },
            { name: 'og:title', content: 'Cloud Corridor' },
            { name: 'og:site_name', content: 'cloudcorridor.com' },
            { name: 'og:url', content: 'https://www.cloudcorridor.com' },
            {
              name: 'og:description',
              content: 'Guiding businesses to the cloud',
            },
            {
              name: 'og:image',
              content: image,
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        {children}
      </>
    )}
  />
)
