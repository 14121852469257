export const replaceMarkdown = str => {
  return str
    .replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, match => {
      const indexOfNameEnd = match.indexOf(']')
      const name = match.substring(1, indexOfNameEnd)
      const url = match.substring(indexOfNameEnd + 2, match.length - 1)

      return `<a href="${url}" target="_blank">${name}</a>`
    })
    .replace(/(\r\n|\n|\r)/gm, '<br/>')
}
