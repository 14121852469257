import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Frame } from '../frame/frame'

import styles from './contact.module.scss'

export const Contact = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <Frame
        id="contact"
        title="Contact Us"
        description={data.graphCMSData.sectionHeadings
          .filter(heading => heading.type === 'Contact')
          .map(heading => (
            <p>{heading.text}</p>
          ))}
      >
        <div className={styles.wrapper}>
          <address className={styles.address}>
            <p>
              <strong>Cloud Corridor LLC.</strong>
              <br />
              726 Route 202 S, Ste 320-355, Bridgewater, NJ 08807
            </p>
          </address>
        </div>
      </Frame>
    )}
  />
)
